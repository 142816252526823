import { useStaticQuery, graphql } from 'gatsby';

// TODO: Add Filtering and sorts

export const useDataList = () => {
  const { allGsSheet1Sheet1 } = useStaticQuery(
    graphql`
      query dataQuery {
        allGsSheet1Sheet1 {
          totalCount
          edges {
            node {
              id
              typeOfHabitat
              author
              country
              linkToSource
              adjustedValues
              units
              service
              state
              method
              unitsYear
              yearAdjustedValues
            }
          }
        }
      }
    `
  );
  return allGsSheet1Sheet1;
};
