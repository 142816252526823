import * as React from 'react';
import './searchform.css';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  getFormattedOptions
} from './searchHelpers';

export const SearchForm: React.FC<{
  services: string[];
  types: string[];
  filters: {
    types: string;
    services: string;
  };
  changeFilter: any;
  initialfilters: any;
}> = ({ services, types, filters, changeFilter, initialfilters }) => {
  // Consider services separated by commas or "and" individual services and remove duplications.
  const servicesList = [...new Set(
    services
    .join(',')
    .split(',')
    .join(' and ')
    .split(/\band\b/)
    .map(item=>item.trim())
    .filter(item=>(item))
  )];
  const typesList = types.filter(function(elem, pos) {
    return types.indexOf(elem) == pos;
  });

  // Make our lists fit with how react select wants.
  const optionsServices = getFormattedOptions(servicesList.sort());
  const optionsTypes = getFormattedOptions(typesList);

  const [servicesState, setServicesState] = useState([]);
  const [typesState, setTypesState] = useState([]);

  function getInitialfilters(initialfilters: [], filterRow: string) {
    let initialStates = initialfilters[filterRow].filter(
      (item: any) => typeof item == 'string'
    );

    initialStates = initialStates.map(function(filterState: string[]) {
      return { key: filterState, value: filterState, label: filterState };
    });
    return initialStates;
  }

  useEffect(() => {
    if (initialfilters) {
      let initalServices = getInitialfilters(initialfilters, 'services');
      setServicesState(initalServices);
      let initalTypes = getInitialfilters(initialfilters, 'types');
      setTypesState(initalTypes);
    }
  }, []);

  // When filters change test if it's a reset and empty everything.
  useEffect(() => {
    if (filters.types.length < 1){
        setTypesState([]);
    }
    if(filters.services.length < 1) {
      setServicesState([]);
    }
    if(filters.types.length < 1 && filters.services.length < 1){
        initialfilters = { types: [], services: [] };
    }

  }, [filters]);

  const changeServices = values => {
    setServicesState(values);
    let filtersState: any[] = [];
    values && servicesState
      ? values.forEach(element => filtersState.push(element.key))
      : [];
    changeFilter(Object.assign(filters, { services: filtersState }));
  };

  const changeTypes = values => {
    setTypesState(values);
    let filtersState: any[] = [];
    values && typesState
      ? values.forEach(element => filtersState.push(element.key))
      : [];
    changeFilter(Object.assign(filters, { types: filtersState }));
  };

  const selectColorStyles = {
    option: (provided: any, state: { isFocused: any; }) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#35b199' : '#fff',
      color: state.isFocused ? '#fff' : '#00223b'
    }),
  };

  return (
    <>
      <div className="search--filter-label font-pn-semibold">
        Search the database
      </div>
      <Select
        isMulti
        name="services"
        value={servicesState}
        className="select height-12 w-2/5 appearance-none border rounded w-1/2 mr-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
        options={optionsServices}
        onChange={changeServices}
        placeholder={'Choose Ecosystem Service'}
        styles={selectColorStyles}
        option={selectColorStyles.option}
        isSearchable={false}
      />

      <Select
        isMulti
        name="types"
        value={typesState}
        className="select height-12 w-2/5 appearance-none border rounded w-1/2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-white"
        options={optionsTypes}
        onChange={changeTypes}
        placeholder={'Choose Habitat'}
        styles={selectColorStyles}
        option={selectColorStyles.option}
        isSearchable={false}
      />
    </>
  );
};
