export function getFloatedPrices(datalist: any) {
  const fullPrices = datalist.map(row => row.node.adjustedvalues);
  let filteredPrices = fullPrices.filter(function(el: string) {
    return el != null;
  });
  let prices = filteredPrices.map(function(price) {
    if (price !== null) {
      let matchePrices = price.match(/(\+|-)?((\d+(\.\d+)?)|(\.\d+))/);
      if (matchePrices && matchePrices[0]) {
        return parseFloat(matchePrices[0]);
      }
    }
  });
  return prices;
}

export function getOptionsList(datalist: any, name: string) {
  const list = datalist.map(row => row.node[name]);
  let options = list.filter(function(elem, pos) {
    if (name === 'units') {
      // Avoid spaces in units.
      elem = elem.replace(/ +/g, ' ').trim();
    }
    if (name === 'method') {
      // Only care about the first part of a method.
      elem = elem.split(',').shift();
    }
    if (elem && !elem.includes('ERROR')) {
      return list.indexOf(elem) === pos;
    }
  });
  // Expand all the items with commas inside the values.
  // For example "Bahamas, Belize, Colombia" becomes 3 values.
  if (name === 'country' || name === 'state') {
    options = [...new Set(
      options
      .join(',')
      .split(',')
      .map(item=>item.trim())
      .filter(item=>(item))
    )];
  }
  options.sort();
  return options;
}

export function getFormattedOptions(list: string[]) {
  return list.map(function(item) {
    return { key: item, value: item, label: item };
  });
}
